import React, { useEffect, useLayoutEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { parseCookies } from 'nookies';

import Main from 'pages/Main';
import Dashboard from 'pages/Dashboard';
import Market from 'pages/Market';
import Support from 'pages/Support';
import Faq from 'pages/Faq';
import CreateToken from 'pages/CreateToken';
import CreateLiquidityPool from 'pages/CreateLiquidityPool';
import Bundler from 'pages/Bundler';
import { useWallet } from 'contexts/wallet';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';

export const RoutesFind = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userAddress } = useWallet();
  const { resetBlurBackground } = useBlurBackground();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (!userAddress || !parseCookies().acceptCookies) {
      navigate('/');
      return;
    }
    resetBlurBackground();
    if (location.pathname === '/') {
      navigate('/createToken');
    }
  }, [userAddress, location.pathname]);

  return (
    <Routes>
      <Route path="/" exact element={<Main />} />
      <Route path="/createToken" exact element={<CreateToken />} />
      <Route path="/bundler" exact element={<Bundler />} />
      <Route path="/createLiquidityPool" exact element={<CreateLiquidityPool />} />
      <Route path="/dashboard" exact element={<Dashboard />} />
      <Route path="/market" exact element={<Market />} />
      <Route path="/support" exact element={<Support />} />
      <Route path="/faq" exact element={<Faq />} />
    </Routes>
  );
};
