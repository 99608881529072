import React, { useMemo } from 'react';

import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import NumberInput from 'components/Inputs/NumberInput';
import Button from 'components/Button';
import { BUTTON_VARIANTS } from 'components/Button/constants';

const Settings = ({
  tokenName,
  onClick,
  tokenImage,
  bundlerSettingsData,
  setBundlerSettingsData
}) => {
  const handleChangeText = (e) => {
    const { name, value } = e.target;

    if (bundlerSettingsData[name] === value) return;
    setBundlerSettingsData({
      ...bundlerSettingsData,
      [name]: value
    });
  };

  const avatarBg = useMemo(() => {
    return tokenImage
      ? {
          backgroundImage: `url(${typeof tokenImage === 'string' ? tokenImage : URL.createObjectURL(tokenImage)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      : {};
  }, [tokenImage]);

  const isValidForm = true; // TODO: validate
  const isDisabled = !isValidForm;

  return (
    <div className="flex flex-col mx-auto overflow-auto pr-[280px] sm:pr-0">
      <div className="flex flex-col items-center justify-center w-[460px] sm:w-full">
        <Typography
          className="text-white"
          text="Bundler Settings"
          variant={TYPOGRAPHY_VARIANTS.HEADER_H2}
        />
        <Typography
          className="text-white text-center opacity-[0.5] mt-[12px]"
          text="By creating token with Bundler, you are guaranteed to be the first one to buy it."
          variant={TYPOGRAPHY_VARIANTS.BODY_M}
        />
      </div>

      <div className="w-full max-w-[460px] sm:max-w-full py-[48px] sm:py-[36px]">
        <div className="w-full flex flex-col items-center space-y-[32px]">
          <React.Fragment>
            <NumberInput
              title="Supply Percentage"
              subtitle="Choose what % of supply you want to buy with bundler"
              unit="%"
              id="supplyPercentage"
              value={bundlerSettingsData.supplyPercentage}
              placeholder="30"
              handleChange={handleChangeText}
            />
            {!!bundlerSettingsData.supplyPercentage &&
              (Number(bundlerSettingsData.supplyPercentage) < 0 ||
                Number(bundlerSettingsData.supplyPercentage) > 100) && (
                <div className="text-[12px] text-red !mt-[10px]">
                  The value must be between 0 and 100
                </div>
              )}
          </React.Fragment>

          <React.Fragment>
            <NumberInput
              title="King of the Hill"
              subtitle="Choose what % of 'King of the Hill' you want to reach with bundler"
              unit="%"
              id="kingOfTheHillPercentage"
              value={bundlerSettingsData.kingOfTheHillPercentage}
              placeholder="30"
              handleChange={handleChangeText}
            />
            {!!bundlerSettingsData.kingOfTheHillPercentage &&
              (Number(bundlerSettingsData.kingOfTheHillPercentage) < 0 ||
                Number(bundlerSettingsData.kingOfTheHillPercentage) > 100) && (
                <div className="text-[12px] text-red !mt-[10px]">
                  The value must be between 0 and 100
                </div>
              )}
          </React.Fragment>

          <div className="w-full flex flex-col justify-center space-x-3 space-y-[10px]">
            <Typography
              className="text-white pl-[24px]"
              text="Supply Percentage on one account"
              variant={TYPOGRAPHY_VARIANTS.HEADER_H4}
            />
            <div className="w-full flex items-center justify-center space-x-3 !ml-0">
              <NumberInput
                title=""
                unit="%"
                unitClassName="!top-[25px]"
                id="walletMinSupplyPercentage"
                value={bundlerSettingsData.walletMinSupplyPercentage}
                placeholder="Min"
                handleChange={handleChangeText}
              />
              <NumberInput
                title=""
                unit="%"
                unitClassName="!top-[25px]"
                id="walletMaxSupplyPercentage"
                value={bundlerSettingsData.walletMaxSupplyPercentage}
                placeholder="Max"
                handleChange={handleChangeText}
              />
            </div>
            <Typography
              className="text-white opacity-50 !ml-[30px]"
              variant={TYPOGRAPHY_VARIANTS.CAPTION}
              text="Choose what % of supply you want to have on one account"
            />
            {!!bundlerSettingsData.minPercentOfSupplyOnOneAccount &&
              !!bundlerSettingsData.maxPercentOfSupplyOnOneAccount &&
              Number(bundlerSettingsData.minPercentOfSupplyOnOneAccount) >
                Number(bundlerSettingsData.maxPercentOfSupplyOnOneAccount) && (
                <div className="text-[12px] text-red !mt-[10px] flex justify-center">
                  The value must be between 0 and 100
                </div>
              )}
          </div>

          <React.Fragment>
            <NumberInput
              title="JITO Tip"
              unit="sol"
              subtitle="Size of Jito tip will affect speed and chance of successful transaction"
              id="jitoTip"
              value={bundlerSettingsData.jitoTip}
              handleChange={handleChangeText}
            />
            {!!bundlerSettingsData.jitoTip &&
              (Number(bundlerSettingsData.jitoTip) < 0 ||
                Number(bundlerSettingsData.jitoTip) > 1000000000) && (
                <div className="text-[12px] text-red !mt-[10px]">
                  The value must be between 1 and 1,000,000,000
                </div>
              )}
          </React.Fragment>

          <React.Fragment>
            <NumberInput
              title="Bumper amount"
              subtitle="Bumper will constantly buy your token to keep it at the top of the dashboard"
              unit="sol"
              id="bumperAmount"
              value={bundlerSettingsData.bumperAmount}
              placeholder="2"
              handleChange={handleChangeText}
            />
            {!!bundlerSettingsData.bumperAmount &&
              (Number(bundlerSettingsData.bumperAmount) < 0 ||
                Number(bundlerSettingsData.bumperAmount) > 1000000000) && (
                <div className="text-[12px] text-red !mt-[10px]">
                  The value must be between 1 and 1,000,000,000
                </div>
              )}
          </React.Fragment>

          <React.Fragment>
            <NumberInput
              title="Bump size"
              unit="sol"
              id="bumpSize"
              value={bundlerSettingsData.bumpSize}
              placeholder="0.01"
              handleChange={handleChangeText}
            />
            {!!bundlerSettingsData.bumpSize &&
              (Number(bundlerSettingsData.bumpSize) < 0 ||
                Number(bundlerSettingsData.bumpSize) > 1000000000) && (
                <div className="text-[12px] text-red !mt-[10px]">
                  The value must be between 1 and 1,000,000,000
                </div>
              )}
          </React.Fragment>

          <React.Fragment>
            <NumberInput
              title="Bump speed"
              id="bumpSpeed"
              unit="sec"
              value={bundlerSettingsData.bumpSpeed}
              placeholder="20"
              handleChange={handleChangeText}
            />
            {!!bundlerSettingsData.bumpSpeed &&
              (Number(bundlerSettingsData.bumpSpeed) < 0 ||
                Number(bundlerSettingsData.bumpSpeed) > 1000000000) && (
                <div className="text-[12px] text-red !mt-[10px]">
                  The value must be between 1 and 1,000,000,000
                </div>
              )}
          </React.Fragment>

          <div className="px-4 py-6 bg-white-100 rounded-[32px] w-full flex flex-col space-y-4">
            <div className="flex items-center justify-start space-x-3">
              <div className="bg-white rounded-full h-[44px] w-[44px]">
                <div style={avatarBg} className="w-full h-full bg-white rounded-full" />
              </div>
              <Typography
                className="text-white"
                text={tokenName}
                variant={TYPOGRAPHY_VARIANTS.BODY_M}
              />
            </div>
            <div className="flex items-center justify-between w-full">
              <Typography
                className="text-white"
                text="Curve Percentage"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <div className="bg-green-100 flex items-center justify-center rounded-[8px] px-2 py-1">
                <Typography
                  className="text-green-1000 opacity-50"
                  text="43.66%"
                  variant={TYPOGRAPHY_VARIANTS.BODY_S}
                />
              </div>
            </div>
            <div className="flex items-center justify-between w-full">
              <Typography
                className="text-white"
                text="Supply Percentage"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <div className="bg-green-100 flex items-center justify-center rounded-[8px] px-2 py-1">
                <Typography
                  className="text-green-1000 opacity-50"
                  text={`${bundlerSettingsData.supplyPercentage || 0}%`}
                  variant={TYPOGRAPHY_VARIANTS.BODY_S}
                />
              </div>
            </div>
            <div className="bg-white-10 border border-white-100 rounded-[16px] flex items-center justify-between px-4 py-3 w-full">
              <Typography
                className="text-white"
                text="Final price"
                variant={TYPOGRAPHY_VARIANTS.BODY_M}
              />
              <Typography
                className="text-white"
                text="$0,42354"
                variant={TYPOGRAPHY_VARIANTS.BODY_M}
              />
            </div>
          </div>

          <Button
            onClick={onClick}
            isDisabled={isDisabled}
            text="Next to Confirmation"
            className="w-[460px] h-[58px] sm:w-full"
            variant={BUTTON_VARIANTS.LARGE}
          />
          <Typography
            className="!mt-4 w-[388px] sm:w-auto text-center text-white opacity-[0.5]"
            text="Click Deploy and confirm the transaction in your wallet. The pool with your tokens will be deployed in several minutes."
            variant={TYPOGRAPHY_VARIANTS.CAPTION}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
