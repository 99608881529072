import React, { useState } from 'react';

import { DashboardLayout } from 'layouts/DashboardLayout';
import TokenInformation from 'components/Bundler/TokenInformation';
import Settings from 'components/Bundler/Settings';
import Confirm from 'components/Bundler/Confirm';
import StepByStep from 'components/Bundler/StepByStep';

import useCreateToken from 'helpers/hooks/useCreateToken';
import useMetadataUploader from 'helpers/hooks/useMetadataUploader';
import useBundler from 'helpers/hooks/useBundler';

const Bundler = () => {
  const [step, setStep] = useState(1);
  const [tokenImage, setTokenImage] = useState(null);
  const [bundlerInfoData, setBundlerInfoData] = useState({
    tokenName: '',
    tokenSymbol: '',
    description: '',
    website: '',
    telegram: '',
    twitter: ''
  });

  const [bundlerSettingsData, setBundlerSettingsData] = useState({
    supplyPercentage: '',
    kingOfTheHillPercentage: '',
    walletMinSupplyPercentage: '',
    walletMaxSupplyPercentage: '',
    jitoTip: '',
    bumperAmount: '',
    bumpSize: '',
    bumpSpeed: ''
  });

  const [bundlerConfirmData, setBundlerConfirmData] = useState({
    autoSellCurvePercentage: ''
  });

  const { createTokenWithBundle, isCreating } = useBundler();

  return (
    <DashboardLayout>
      {step === 1 && (
        <TokenInformation
          tokenImage={tokenImage}
          setTokenImage={setTokenImage}
          bundlerData={bundlerInfoData}
          setBundlerData={setBundlerInfoData}
          onDeploy={() => setStep(2)}
        />
      )}
      {step === 2 && (
        <Settings
          tokenName={bundlerInfoData.tokenName}
          tokenImage={tokenImage}
          bundlerSettingsData={bundlerSettingsData}
          setBundlerSettingsData={setBundlerSettingsData}
          onClick={() => setStep(3)}
        />
      )}
      {step === 3 && (
        <Confirm
          tokenImage={tokenImage}
          tokenInfoData={bundlerInfoData}
          bundlerSettingsData={bundlerSettingsData}
          bundlerConfirmData={bundlerConfirmData}
          setBundlerConfirmData={setBundlerConfirmData}
          isDeploying={isCreating}
          onClick={() =>
            createTokenWithBundle({
              tokenImage,
              bundlerData: {
                ...bundlerSettingsData,
                ...bundlerInfoData,
                ...bundlerConfirmData
              }
            })
          }
        />
      )}
      {(step === 1 || step === 2) && <StepByStep step={step} />}
    </DashboardLayout>
  );
};

export default Bundler;
