import React from 'react';
import clsx from 'clsx';

import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

const TextInput = ({
  className,
  title,
  min = 1,
  max = 1000000000,
  id = null,
  value,
  handleChange,
  placeholder,
  subtitle,
  unit,
  unitClassName
}) => {
  return (
    <div
      className={clsx(
        `flex flex-col items-start relative justify-center space-y-[10px] w-full`,
        className
      )}>
      <Typography
        className="text-white pl-[24px]"
        text={title}
        variant={TYPOGRAPHY_VARIANTS.HEADER_H4}
      />
      <input
        type="number"
        min={min}
        max={max}
        id={id}
        name={id}
        autoComplete="off"
        value={value}
        className="bg-transparent text-white rounded-[100px] py-[20px] px-[24px] sm:py-[16px] sm:px-[20px] border border-white-100 w-full"
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
      />
      {unit && (
        <Typography
          className={`text-white absolute opacity-50 ml-[30px] right-[28px] top-[42px]  ${unitClassName}`}
          variant={TYPOGRAPHY_VARIANTS.BODY_S}
          text={unit}
        />
      )}
      {subtitle && (
        <Typography
          className="text-white opacity-50 ml-[30px]"
          variant={TYPOGRAPHY_VARIANTS.CAPTION}
          text={subtitle}
        />
      )}
    </div>
  );
};

export default TextInput;
