// export const IS_DEV = window.location.hostname !== 'www.rocketlauncher.gg';
export const IS_DEV = false;

const MAIN_SOLANA_ENDPOINT = 'https://emera-upzvpf-fast-mainnet.helius-rpc.com';
const MAIN_LOOKUP_TABLE_ADDRESS = '8F7sgcZsDLHgTsYL7WzsJHKfi8KUH18dQmkTbrq3vNtK';
const MAIN_CLMM_PROGRAM_ID = 'CAMMCzo5YL8w4VFF8KVHrK22GGUsp5VTaW7grrKgrWqK';
const MAIN_FEE_RECEIVER = '8iv4cFhk7s7SCysedKgBC2haqEfvx8HmJ75PP5pmjn1b';
const MAIN_PROGRAM_ID = 'RoCKeTyq1Wf8Co6e2x1RQCC6uxRkZrQ3mDJUEoQryhh';

const DEV_SOLANA_ENDPOINT = 'https://api.devnet.solana.com';
const DEV_LOOKUP_TABLE_ADDRESS = 'AvTJscQQjDNMfKeeGgb9VdqE4DY818c92pBwbq1K25PX';
const DEV_CLMM_PROGRAM_ID = 'devi51mZmdwUJGU9hjN27vEz64Gps7uUefqxg27EAtH';
const DEV_FEE_RECEIVER = '5TLHYfEXHmNCctnCurLiPzLkwW46gxECePQ9BTwGYfvD';
const DEV_PROGRAM_ID = 'RoCKeTyq1Wf8Co6e2x1RQCC6uxRkZrQ3mDJUEoQryhh';

export const SOLANA_ENDPOINT = IS_DEV ? DEV_SOLANA_ENDPOINT : MAIN_SOLANA_ENDPOINT;
export const LOOKUP_TABLE_ADDRESS = IS_DEV ? DEV_LOOKUP_TABLE_ADDRESS : MAIN_LOOKUP_TABLE_ADDRESS;
export const CLMM_PROGRAM_ID = IS_DEV ? DEV_CLMM_PROGRAM_ID : MAIN_CLMM_PROGRAM_ID;
export const FEE_RECEIVER = IS_DEV ? DEV_FEE_RECEIVER : MAIN_FEE_RECEIVER;
export const PROGRAM_ID = IS_DEV ? DEV_PROGRAM_ID : MAIN_PROGRAM_ID;

export const IPFS_GATEWAY = 'https://gateway.pinata.cloud/ipfs';

export const TOKEN_DECIMALS = 6;

export const SIGN_MESSAGE = 'By signing this message I am confirming ownership of this account';
export const ARG_DECIMAL_PLACES = 9;
export const NUM_WALLETS = 21;
export const PUMPFUN_BUMPER_AMOUNT_FEE_PCT = 1; // 1%
export const PUMPFUN_BUNDLE_FEE = 500_000_000; // 0.5 SOL
