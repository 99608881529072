import React, { useMemo, useState } from 'react';

import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

const StepByStep = ({ step }) => {
  const [supButtonOpen, setSupButtonOpen] = useState(false);

  const supButtonContent = useMemo(() => {
    if (step === 1) {
      return {
        title: 'Step-by-Step Guide to create tokens',
        content: () => {
          return (
            <div className="flex flex-col space-y-[12px]">
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="1 STEP"
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
                <br />
                <Typography
                  className="text-white opacity-[0.5]"
                  text="Create your token in two steps"
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="Customize your Solana Token exactly the way you envision it"
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="Input: avatar, name, symbol, description and total supply or your token."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="Click Create Token, accept the transactions and wait until your tokens ready."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="2 STEP"
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
                <br />
                <Typography
                  className="text-white opacity-[0.5]"
                  text="1. Choose size your market cap in USD. 95% tokens will go in LP."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
                <br />
                <Typography
                  className="text-white opacity-[0.5]"
                  text="2. Click on DEPLOY, accept the transactions and wait until your LP is ready."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="The cost of LP creation is 0.95 SOL."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
            </div>
          );
        }
      };
    } else if (step === 2) {
      return {
        title: 'Bundler tool info',
        content: () => {
          return (
            <div className="flex flex-col space-y-0.5">
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="Bundler is a tool to buy your token ahead of everyone on multiple wallets."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
              <div>
                <Typography
                  className="text-white opacity-[0.5]"
                  text="We will create 25 wallets for you, including 1 deployer wallet and 24 bundling wallets."
                  variant={TYPOGRAPHY_VARIANTS.CAPTION}
                />
              </div>
            </div>
          );
        }
      };
    }
  }, [step]);

  return (
    <React.Fragment>
      <div className="fixed bottom-[90px] right-[40px] flex flex-col items-end max-w-[350px] space-y-2">
        {supButtonOpen && (
          <div className="opacityBackgroundBlurClass flex flex-col space-y-2.5 bg-white-100 rounded-[32px] w-auto sm:w-[94%] max-h-[85vh] overflow-auto p-4 text-white-500">
            <Typography
              className="text-white"
              text={supButtonContent?.title}
              variant={TYPOGRAPHY_VARIANTS.BODY_S}
            />
            <div className="text-[12px]">{supButtonContent?.content()}</div>
          </div>
        )}
      </div>
      <button
        className={`fixed bottom-[24px] right-[40px] sm:bottom-4 sm:right-4 cursor-pointer flex items-center justify-center ${supButtonOpen ? 'p-[0.5px] btn-gradient' : 'p-[0.5px] bg-[#212121]'} rounded-full max-w-max h-[56px]`}
        onClick={() => setSupButtonOpen(!supButtonOpen)}>
        <div
          className={`${supButtonOpen ? 'bg-[#212121]' : 'bg-transparent'} w-full h-full rounded-full flex items-center justify-center px-4 space-x-[6px] text-white`}>
          <img src="/infoIcon.svg" alt="info icon" />
          {!supButtonOpen && (
            <Typography
              className={'sm:hidden'}
              text={supButtonContent?.title}
              variant={TYPOGRAPHY_VARIANTS.BODY_S}
            />
          )}
        </div>
      </button>
    </React.Fragment>
  );
};

export default StepByStep;
