import React, { useMemo, useState } from 'react';

import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import NumberInput from 'components/Inputs/NumberInput';
import Button from 'components/Button';
import { BUTTON_VARIANTS } from 'components/Button/constants';

const Confirm = ({
  tokenImage,
  tokenInfoData,
  bundlerSettingsData,
  bundlerConfirmData,
  setBundlerConfirmData,
  isDeploying,
  onClick
}) => {
  const avatarBg = useMemo(() => {
    return tokenImage
      ? {
          backgroundImage: `url(${typeof tokenImage === 'string' ? tokenImage : URL.createObjectURL(tokenImage)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      : {};
  }, [tokenImage]);

  const handleChangeText = (e) => {
    const { name, value } = e.target;

    if (bundlerConfirmData[name] === value) return;
    setBundlerConfirmData({
      ...bundlerConfirmData,
      [name]: value
    });
  };

  return (
    <div className="flex flex-col mx-auto overflow-auto pr-[280px] sm:pr-0">
      <div className="flex flex-col items-center justify-center w-[460px] sm:w-full">
        <Typography
          className="text-white"
          text="Confirm Information"
          variant={TYPOGRAPHY_VARIANTS.HEADER_H2}
        />
        <Typography
          className="text-white text-center opacity-[0.5] mt-[12px]"
          text="Review your token and bundler settings"
          variant={TYPOGRAPHY_VARIANTS.BODY_M}
        />
      </div>

      <div className="w-full max-w-[460px] sm:max-w-full py-[48px] sm:py-[36px]">
        <div className="w-full flex flex-col items-center space-y-[32px]">
          <div className="px-4 py-6 bg-white-100 rounded-[32px] h-[92px] w-full flex items-center justify-start space-x-3">
            <div className="bg-white rounded-full h-[44px] w-[44px]">
              <div style={avatarBg} className="w-full h-full bg-white rounded-full" />
            </div>
            <Typography
              className="text-white"
              text={tokenInfoData.tokenName}
              variant={TYPOGRAPHY_VARIANTS.BODY_M}
            />
          </div>
          <div className="px-4 py-6 bg-white-100 rounded-[32px] w-full flex flex-col items-center justify-start space-y-4">
            <div className="flex items-center justify-between w-full">
              <Typography
                className="text-white"
                text="Supply Percentage"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <Typography
                className="text-white opacity-50"
                text={`${bundlerSettingsData.supplyPercentage} %`}
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
            </div>
            {/* <div className="flex items-center justify-between w-full">
              <Typography
                className="text-white"
                text="Curve Percentage"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <Typography
                className="text-white opacity-50"
                text={`${bundlerSettingsData.curvePercentage} %`}
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
            </div> */}
            <div className="flex items-center justify-between w-full">
              <Typography
                className="text-white"
                text="King of the hill"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <Typography
                className="text-white opacity-50"
                text={`${bundlerSettingsData.kingOfTheHillPercentage} %`}
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
            </div>
            <div className="flex items-center justify-between w-full">
              <Typography
                className="text-white"
                text="JITO Tip"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <Typography
                className="text-white opacity-50"
                text={`${bundlerSettingsData.jitoTip} SOL`}
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
            </div>
            <div className="flex items-center justify-between w-full">
              <Typography
                className="text-white"
                text="Bumper amount"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <Typography
                className="text-white opacity-50"
                text="2 SOL"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
            </div>
            <div className="bg-white-10 border border-white-100 rounded-[16px] flex items-center justify-between px-4 py-3 w-full">
              <Typography
                className="text-white"
                text="Final price"
                variant={TYPOGRAPHY_VARIANTS.BODY_M}
              />
              <Typography
                className="text-white"
                text="$0,42354"
                variant={TYPOGRAPHY_VARIANTS.BODY_M}
              />
            </div>
          </div>
          <React.Fragment>
            <NumberInput
              title="Autosell, when curve reaches"
              unit="%"
              id="autoSellCurvePercentage"
              value={bundlerConfirmData.autoSellCurvePercentage}
              placeholder="97"
              handleChange={handleChangeText}
            />
            {!!bundlerConfirmData.autoSellCurvePercentage &&
              (Number(bundlerConfirmData.autoSellCurvePercentage) < 0 ||
                Number(bundlerConfirmData.autoSellCurvePercentage) > 100) && (
                <div className="text-[12px] text-red !mt-[10px]">
                  The value must be between 1 and 100
                </div>
              )}
          </React.Fragment>

          <Button
            onClick={onClick}
            isDisabled={isDeploying}
            text="Create token"
            className="w-[460px] h-[58px] sm:w-full"
            variant={BUTTON_VARIANTS.LARGE}
          />
          <Typography
            className="!mt-4 w-[388px] sm:w-auto text-center text-white opacity-[0.5]"
            text="Click Deploy and confirm the transaction in your wallet. The pool with your tokens will be deployed in several minutes."
            variant={TYPOGRAPHY_VARIANTS.CAPTION}
          />
        </div>
      </div>
    </div>
  );
};

export default Confirm;
