import React, { useRef, useMemo } from 'react';

import { useWallet } from 'contexts/wallet';
import Typography from 'components/Typography';
import TextInput from 'components/Inputs/TextInput';
import TextAreaInput from 'components/Inputs/TextAreaInput';
import Button from 'components/Button';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import { MAX_IMAGE_SIZE } from './constants';

const TokenInformation = ({ setTokenImage, tokenImage, onDeploy, bundlerData, setBundlerData }) => {
  const REQUIRED_FIELDS = ['tokenName', 'tokenSymbol', 'description'];
  const ref = useRef();
  const { userAddress } = useWallet();

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setTokenImage(file);
    } else {
      setTokenImage(null);
    }
    event.target.value = null;
  };

  const handleChangeText = (e) => {
    const { name, value } = e.target;

    if (bundlerData[name] === value) return;
    setBundlerData({
      ...bundlerData,
      [name]: value
    });
  };

  const avatarBg = useMemo(() => {
    return tokenImage
      ? {
          backgroundImage: `url(${typeof tokenImage === 'string' ? tokenImage : URL.createObjectURL(tokenImage)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      : {};
  }, [tokenImage]);

  const isValidForm = tokenImage && REQUIRED_FIELDS.every((key) => bundlerData[key]?.length > 0);
  const isDisabled = !isValidForm || !userAddress;

  return (
    <div className="flex flex-col mx-auto overflow-auto pr-[280px] sm:pr-0">
      <div className="flex flex-col items-center justify-center w-[460px] sm:w-full">
        <Typography
          className="text-white"
          text="Token Information"
          variant={TYPOGRAPHY_VARIANTS.HEADER_H2}
        />
        <Typography
          className="text-white text-center opacity-[0.5] mt-[12px]"
          text="Customize your Solana Token exactly the way you envision it. Less than 5 minutes, at an affordable cost."
          variant={TYPOGRAPHY_VARIANTS.BODY_M}
        />
      </div>

      <div className="w-full max-w-[460px] sm:max-w-full py-[48px] sm:py-[36px]">
        <div className="w-full flex flex-col items-center space-y-[32px]">
          <div className="relative flex flex-col items-center justify-center">
            {!!tokenImage && (
              <div
                onClick={() => setTokenImage(null)}
                className="hover:opacity-75 z-[3] cursor-pointer rounded-full absolute top-0 right-0 bg-red w-[24px] h-[24px] flex flex-col items-center justify-center">
                <img
                  className="w-[16px] h-[16px]"
                  src="/closeIcon.svg"
                  alt="remove token image icon"
                />
              </div>
            )}
            <input
              ref={ref}
              accept="image/png, image/jpeg"
              className="hidden"
              id="contained-button-file"
              type="file"
              onChange={handleImageChange}
              maxsize={MAX_IMAGE_SIZE}
            />

            <button
              type="button"
              onClick={() => ref.current.click()}
              className="rounded-full flex items-center justify-center logo-btn-gradient h-[100px] w-[100px]">
              {tokenImage ? (
                <div
                  className="hover:opacity-90 rounded-full w-[98%] h-[98%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  style={avatarBg}></div>
              ) : (
                <div className="hover:opacity-90 rounded-full bg-black-1000 flex items-center justify-center w-[98%] h-[98%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <img className="opacity-30" src="/imageLoader.svg" alt="image loader" />
                </div>
              )}
            </button>
          </div>
          <TextInput
            title="Name"
            maxLength="32"
            id="tokenName"
            value={bundlerData.tokenName}
            handleChange={handleChangeText}
            placeholder="Token name"
          />
          <TextInput
            title="Symbol"
            maxLength="8"
            id="tokenSymbol"
            value={bundlerData.tokenSymbol}
            handleChange={handleChangeText}
            placeholder="Token symbol (max 8 characters)"
          />
          <TextAreaInput
            title="Description"
            id="description"
            value={bundlerData.description}
            handleChange={handleChangeText}
            placeholder="Token description"
          />
          <TextInput
            title="Website"
            id="website"
            value={bundlerData.website}
            handleChange={handleChangeText}
            placeholder="(optional)"
          />
          <TextInput
            title="Telegram"
            id="telegram"
            value={bundlerData.telegram}
            handleChange={handleChangeText}
            placeholder="(optional)"
          />
          <TextInput
            title="Twitter"
            id="twitter"
            value={bundlerData.twitter}
            handleChange={handleChangeText}
            placeholder="(optional)"
          />
          <Button
            isDisabled={isDisabled}
            onClick={onDeploy}
            text="Next to Bundler"
            className="w-[460px] h-[58px] sm:w-full"
            variant={BUTTON_VARIANTS.LARGE}
          />
        </div>
      </div>
    </div>
  );
};

export default TokenInformation;
