import React, { useEffect, useLayoutEffect, lazy, Suspense } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { parseCookies } from 'nookies';

import { useWallet } from 'contexts/wallet';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';

const Main = lazy(() => import('pages/Main'));
const Market = lazy(() => import('pages/Market'));
const MyTokens = lazy(() => import('pages/MyTokens'));
const Bundler = lazy(() => import('pages/Bundler'));
const CreateToken = lazy(() => import('pages/CreateToken'));
const CreateLiquidityPool = lazy(() => import('pages/CreateLiquidityPool'));
const Support = lazy(() => import('pages/Support'));
const Faq = lazy(() => import('pages/Faq'));

export const RoutesFind = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userAddress } = useWallet();
  const { resetBlurBackground } = useBlurBackground();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (!userAddress || !parseCookies().acceptCookies) {
      navigate('/');
      return;
    }
    resetBlurBackground();
    if (location.pathname === '/') {
      navigate('/market');
    }
  }, [userAddress, location.pathname]);

  return (
    <Routes>
      {/*Main page*/}
      <Route
        path="/"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Main />
          </Suspense>
        }
      />

      {/*Menu*/}
      <Route
        path="/market"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Market />
          </Suspense>
        }
      />
      <Route
        path="/mytokens"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <MyTokens />
          </Suspense>
        }
      />

      {/*Pumpfun*/}
      <Route
        path="/bundler"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Bundler />
          </Suspense>
        }
      />

      {/*V3*/}
      <Route
        path="/createToken"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <CreateToken />
          </Suspense>
        }
      />
      <Route
        path="/createLiquidityPool"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <CreateLiquidityPool />
          </Suspense>
        }
      />

      {/*Other*/}
      <Route
        path="/support"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Support />
          </Suspense>
        }
      />
      <Route
        path="/faq"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Faq />
          </Suspense>
        }
      />
    </Routes>
  );
};
