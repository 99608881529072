import { useCallback, useState } from 'react';
import { IS_DEV } from 'config';

import { useNotifications } from 'contexts/notifications';
import { useSnackbar } from 'notistack';

const useBundler = () => {
  const [isCreating, setIsCreating] = useState(false);
  const { showInfoNotification, showErrorNotification } = useNotifications();
  const { closeSnackbar } = useSnackbar();

  async function doBundleRequest(params) {
    // Build form data:
    const formData = new FormData();
    formData.append('image', params.tokenImage);
    formData.append('data', JSON.stringify(params.bundlerData));

    // Set up the request
    return await fetch(
      IS_DEV
        ? process.env.REACT_APP_DEVNET_BUNDLER_URL || '/bundle'
        : process.env.REACT_APP_MAINET_BUNDLER_URL || '/bundle',
      {
        method: 'POST',
        body: formData
      }
    );
  }

  const createTokenWithBundle = useCallback(
    async (params, onSuccess = () => {}) => {
      let notificationId;
      try {
        setIsCreating(true);
        notificationId = showInfoNotification(
          'Creating token...',
          'This may take couple of seconds'
        );
        const result = await doBundleRequest(params);
        if (!result.ok) {
          throw new Error();
        }
        const tokenMint = (await result.json()).result.tokenMint;
        showInfoNotification('Token Created!', `https://pump.fun/${tokenMint}`);
        console.log(`Token created: https://pump.fun/${tokenMint}`);
        return tokenMint;
      } catch (e) {
        showErrorNotification('Error during creating token');
        return null;
      } finally {
        closeSnackbar(notificationId);
        setIsCreating(false);
      }
    },
    [showInfoNotification, showErrorNotification, closeSnackbar]
  );

  return {
    isCreating,
    createTokenWithBundle
  };
};

export default useBundler;
